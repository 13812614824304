import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import '@/assets/style/theme/index.css'
import { Service } from '@/api/service.js'
import api from '@/api/api.js'
import storage from '@/assets/js/storage.js'
import '@/assets/style/base.less'
import './config/rem.js'
import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts
Vue.prototype.$axios = Service
Vue.prototype.$api = api
Vue.prototype.$storage = storage
Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
