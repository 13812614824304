const storage = {
  setToken: function (data) {
    window.sessionStorage.setItem('token', data)
  },
  setTokenExpiresTime: function (data) {
    window.sessionStorage.setItem('tokenExpiresTime', data)
  },
  setUserInfo: function (data) {
    window.sessionStorage.setItem('userId', data.userId)
    if(data.fileDynamic.Avatar.startsWith('/static')){
      data.fileDynamic.Avatar = require('../../assets/img/default/avator.jpeg')
    }
    window.localStorage.setItem('userInfo_pc', JSON.stringify(data))
  },
  setBindUserList:function(data){
    window.sessionStorage.setItem('bindUserList_pc', data);
  },
  exitLogin: function () {
    window.sessionStorage.clear();
  },
  /***********************f分界线***************************************/
  getToken: function () {
    return window.sessionStorage.getItem('token');
  },
  getTokenExpiresTime: function () {
    return window.sessionStorage.getItem('tokenExpiresTime');
  },
  getUserId: function () {
    let userId = window.sessionStorage.getItem('userId')
    if (userId == '') {
      userId = null
    }
    return userId
  },
  getUserInfo: function () {
    return JSON.parse(window.localStorage.getItem('userInfo_pc'))
  }
}
export default storage;
