import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    beforeEnter (to, from, next) {
      if (window.sessionStorage.getItem('token')) {
        next({
          name: 'home'
        })
      } else {
        next({
          name: 'login'
        })
      }
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue'),
    beforeEnter (to, from, next) {
      if (window.sessionStorage.getItem('token')) {
        next()
      } else {
        next({
          name: 'login'
        })
      }
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/detail.vue'),
    beforeEnter (to, from, next) {
      if (window.sessionStorage.getItem('token')) {
        next()
      } else {
        next({
          name: 'login'
        })
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    beforeEnter (to, from, next) {
      if (window.sessionStorage.getItem('token')) {
        next({
          name: 'home'
        })
      } else {
        next()
      }
    }
  },
  {
    path: '*',
    redirect: '/home'
  }
]

const router = new VueRouter({
  routes
})

export default router
