import axios from 'axios'
import storage from '@/assets/js/storage.js'
import { Message, Loading } from 'element-ui'
//const ConfigBaseURL = 'https://api.quanzz1314.top/api/fitness'
const ConfigBaseURL = 'http://api.howiter.com/api/fitness'
// const ConfigBaseURL = 'http://localhost:7100/api/fitness'
//const ConfigBaseURL = 'http://192.168.2.1:7100/api/fitness'
let loadingInstance = null // 这里是loading
// 使用create方法创建axios实例
export const Service = axios.create({
  timeout: 7000, // 请求超时时间
  baseURL: ConfigBaseURL,
  method: 'post',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  }
})
// 添加请求拦截器
Service.interceptors.request.use(config => {
  if(!(config.loading === false)){
    loadingInstance = Loading.service({
      lock: true,
      background:"transparent",
      text: '拼命加载中',
      spinner:'el-icon-loading'
    })
  }

  config.headers['x-unicorn-token'] = storage.getToken();
  return config
})
// 添加响应拦截器
Service.interceptors.response.use(response => {
  if(loadingInstance != null){loadingInstance.close()}
  console.log(response)
  if(!response.data.success){
    Message({
      message: response.data.msg,
      type: 'error',
      duration: 3000
    })
  }
  return response.data
}, error => {
  console.log('TCL: error', error)
  const msg = error.Message !== undefined ? error.Message : ''
  Message({
    message: '网络错误' + msg,
    type: 'error',
    duration: 3000
  })
  if(loadingInstance != null){loadingInstance.close()}
  return Promise.reject(error)
})
// 下载
export const Download = axios.create({
  timeout: 7000, // 请求超时时间
  baseURL: ConfigBaseURL,
  method: 'post',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})
// 添加请求拦截器
Download.interceptors.request.use(config => {
  if(!(config.loading === false)){
    loadingInstance = Loading.service({
      lock: true,
      background:"transparent",
      text: '拼命下载中，请稍等',
      spinner:'el-icon-loading'
    })
  }

  config.headers['x-unicorn-token'] = storage.getToken();
  return config
})
// 添加响应拦截器
Download.interceptors.response.use(response => {
  if(loadingInstance != null){loadingInstance.close()}
  return response
}, error => {
  console.log('TCL: error', error)
  const msg = error.Message !== undefined ? error.Message : ''
  Message({
    message: '网络错误' + msg,
    type: 'error',
    duration: 3000
  })
  if(loadingInstance != null){loadingInstance.close()}
  return Promise.reject(error)
})
