import store from '../store'
(function () {
  function a () {
    let b = document.documentElement.clientWidth
    b = b > 1920 ? 1920 : b
    const c = b / 1920 * 100
    document.getElementsByTagName('html')[0].style.fontSize = c + 'px'
    store.commit('setRem', c)
  }

  a()
  window.onresize = a
})()
