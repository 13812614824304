import {
  Download,
  Service
} from './service'
const api = {
  login(data) {
    return Service({
      url: '/login',
      data: data
    })
  },
  queryPlanGroupList(data) {
    return Service({
      url: '/fitnessPlanGroup/list/limit',
      data: data
    })
  },
  queryBindUserList(data) {
    return Service({
      url: '/user/bind/list/limit',
      data: data
    })
  },
  queryPlanList(data) {
    return Service({
      url: '/fitnessPlan/list/limit',
      data: data
    })
  },
  queryPlanRecord:function(data){
    return Service({
      url: '/trainingRecord/query/planId',
      data: data
    })
  },
  realtimeBaseList:function(data){
    return Service({
      url: '/pc/query/realtime',
      loading:false,
      data: data
    })
  },
  realtimeDetail:function(data){
    return Service({
      url: '/pc/query/detail/data?userId=' + data.userId,
      loading:false,
      method: 'get',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    })
  },
  downData:function(data){
    return Download({
      url: '/trainingRecord/export',
      method: 'post',
      data: data,
      responseType: 'blob'
    })
  }
}
export default api;
